// custom typefaces
import "typeface-merriweather"
import "typeface-abel"

// performant images in safari i guess
// https://dev.to/arnonate/gatsby-images-and-safari-be8
export const onClientEntry = async () => {
  if (typeof IntersectionObserver === "undefined") {
    await import("intersection-observer")
  }
}

export const onInitialClientRender = () => {
  require("typeface-abel")
  require("typeface-merriweather")
}
